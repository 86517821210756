/** @jsxRuntime classic */
/** @jsx jsx */

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { jsx } from '@emotion/react'
import {
    Avatar,
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    ListItem,
    Typography,
    FormHelperText,
    FormGroup,
    styled,
    Stepper,
    Step,
    StepLabel,
    Stack,
    InputAdornment,
    IconButton,
    CircularProgress
} from '@mui/material'
import { ErrorMessage, keys, Placeholder, Regex, TextStrings } from '../../constants'
import variables from '../../settings/_variables.scss'
import BuntonLogo from '../../assets/icons/LOGO_WITH_TEXT.svg'
import { Button, TextInput } from '../../components'
import Icons from '../../components/Icons'
import { useForm, Controller } from 'react-hook-form'

import LinkedinLogo from '../../assets/icons/linkedin.svg'
import GoogleLogo from '../../assets/icons/google.svg'

import { LoginSocialGoogle, LoginSocialLinkedin } from 'reactjs-social-login'
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { useMutation, useQuery } from '@apollo/client'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { registrationSuccess } from '../../redux/actions/registration'

import PageMetaTags from '../../base/PageMetaTags'
import Loader from '../../components/Loader'

import CheckIcon from '../../assets/icons/CHECK.svg'
import { styles } from './styles'
import { loginSuccess, loginFailure, loginCheckAuth } from '../../redux/actions/login'
import Cookies from 'universal-cookie'
import { LOGIN_MUTATION } from '../../gql/queries'
import { getFormattedObjectForData } from '../../utils/commonFunctions'
import { StyledSocialLoginButton } from '../../base/commonStyles'
import AvatarDefault from '../../assets/images/avatar.png'
import { PASSWORD_REGEX, experiencesList } from '../../utils/constantData'
import TypingLoader from '../../assets/icons/BUN_typing.gif'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { updateProfileDataFromDB } from '../../redux/actions/profileDetail'
import StepOne from './StepOne'
import { CURRENT_USER_QUERY, REGISTRATION_MUTATION } from '../../queries/registrationQueries'
import RegistrationStepper from '../../components/elements/RegistrationStepper'
import RegistrationChipBox from '../../components/elements/RegistrationChipBox'
import PasswordValidationSection from '../../components/organisams/PasswordValidationSection'
import { clarity } from 'react-microsoft-clarity'

const StyledCheckedBox = styled(Box)`
    height: 25px;
    width: 25px;
    border-radius: 35px;
    text-align: center;
    border: 1px solid ${variables.CYAN};
    background-color: ${variables.CYAN};
    display: flex;
    align-items: center;
    justify-content: center;
`

const StyledCheckedBoxUnchecked = styled(Box)`
    height: 25px;
    width: 25px;
    border-radius: 35px;
    text-align: center;
    border: 1px solid ${variables.CYAN};
    background-color: ${variables.WHITE1};
`

const cookies = new Cookies()
const Registration = () => {
    const location = useLocation()
    const { allStates, allIndustries, allDepartments } = useSelector(
        state => state.initial
    )
    const formattedAllStates = getFormattedObjectForData(allStates)

    let noPrefData = {
        __typename: 'location',
        name: 'No Preference',
        checked: false,
        label: 'No Preference',
        value: 'No Preference'
    }

    formattedAllStates.push(noPrefData)


    const formattedAllIndustries = getFormattedObjectForData(allIndustries)
    const formattedExperiences = getFormattedObjectForData(experiencesList)
    
    const formattedAllDepartments = getFormattedObjectForData(allDepartments)

    const masterMessages = [
        {
            question: 'Welcome to bunton, nice to meet you. What’s your name?',
            options: [],
            firstStep: true,
            finalStep: false,
            ans: '',
            ansToShow: '',
            key: 'name'
        },
        {
            question: 'what is your current department? You can select 2.',
            options: [...formattedAllDepartments],
            firstStep: false,
            finalStep: false,
            ans: '',
            ansToShow: '',
            allowedAnswers: 2,
            key: 'department'
        },
        {
            question: 'Thank you. How many years of experience do you have?',
            options: [...formattedExperiences],
            firstStep: false,
            finalStep: false,
            ans: '',
            ansToShow: '',
            allowedAnswers: 1,
            key: 'experience'
        },
        {
            question: 'Great. What industries do you prefer to work in? Select up to 2.',
            options: [...formattedAllIndustries],
            firstStep: false,
            finalStep: false,
            ans: '',
            ansToShow: '',
            allowedAnswers: 2,
            key: 'industry'
        },
        {
            question: 'Where do you like to work? Select up to 3 locations.',
            options: [...formattedAllStates],
            firstStep: false,
            finalStep: true,
            allowedAnswers: 3,
            ans: '',
            ansToShow: '',
            key: 'location'
        }
    ]

    const messages = JSON.parse(JSON.stringify(masterMessages))
    let loaderTimerRef = useRef()
    let questionTimerRef = useRef()
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(0)
    const [msg, setMsg] = useState([messages[0]])
    const [prefCheck, setPrefCheck] = useState(false)
    const [itemKey, setItemkey] = useState('')
    const [performScroll, setPerformScroll] = useState(true)
    const [showTextInputAndButton, setShowTextInputAndButton] = useState(true)
    // const [showLoaderTextInput, setShowLoaderTextInput] = useState(false)
    const [showChipSubmitButton, setShowChipSubmitButton] = useState(false)
    const [startTimer, setStartTimer] = useState(false)
    const [showRegistrationForm, setShowRegistrationForm] = useState(false)
    const [name, setName] = useState('')
    const [showStepOne, setShowStepOne] = useState(true)
    const [apiError, setApiError] = useState('')
    const [validationError, setValidationError] = useState('')
    const [showLoginByEmail, setShowLoginByEmail] = useState(false)

    const [linkedinLoading, setLinkedinLoading] = useState(false)
    const [googleLoading, setGoogleLoading] = useState(false)
    const [pageLoading, setPageLoading] = useState(false)

    const firstNameFormRef = React.useRef(null)
    const chipFormRef = React.useRef(null)
    
    const REDIRECT_URI = window.location.href

    const [showPassword, setShowPassword] = useState(false)

    const handleClickShowPassword = () => setShowPassword(show => !show)

    const handleMouseDownPassword = event => {
        event.preventDefault()
    }
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [registrationMutation, { loading }] = useMutation(REGISTRATION_MUTATION, {
        onCompleted(data) {
            dispatch(registrationSuccess(data))
            navigate(`/otp/${btoa(data.createUser.id)}`, { state: { ...location.state } }) 
        },
        onError(error) {
            setApiError(error)
        }
    })

    useEffect(() => {
        const listener = event => {
            localStorage.setItem('pageType', 'registration')
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                if (showRegistrationForm) {
                    handleSubmit(onSubmit)()
                }
                if (showTextInputAndButton) {
                    firstNameFormRef.current.click()
                }
                if (showChipSubmitButton) {
                    chipFormRef.current.click()
                }
                event.preventDefault()
            }
        }
        document.addEventListener('keydown', listener)
        return () => {
            document.removeEventListener('keydown', listener)
        }
    }, [showRegistrationForm, showTextInputAndButton, showChipSubmitButton])

    const [loginMutation, { loading: loginLoading }] = useMutation(LOGIN_MUTATION, {
        onCompleted(data) {
            dispatch(loginSuccess(data))
            setPageLoading(false)
            cookies.set('BUNTON_ACCESS_TOKEN', data.signIn.accessToken, { path: '/' })
            cookies.set('BUNTON_CLIENT_TOKEN', data.signIn.client, { path: '/' })
            cookies.set('BUNTON_EXPIRY', data.signIn.expiry, { path: '/' })
            cookies.set('BUNTON_UID', data.signIn.uid, { path: '/' })
            
            refetch()
            if (data.signIn.lastLogoutAt) {
                navigate('/dashboard')
            } else {
                navigate('/profileSetup')
            }
        },
        onError(error) {
            setApiError(error)
            // dispatch(loginFailure(error.message))
        }
    })

    const { refetch } = useQuery(CURRENT_USER_QUERY, {
        fetchPolicy: 'network-only',
        onCompleted(data) {
            if(data.currentUser && data.currentUser.id) {
                clarity.identify(data.currentUser.id, {
                    name: data.currentUser.name,
                    email: data.currentUser.email,
                    userRole: data.currentUser.userRole,
                });
            }
            dispatch(loginCheckAuth(data))
            // saving education data in redux
            let educationData = []
            educationData['key'] = keys.education
            educationData['data'] = data.educationDetails ? data.educationDetails : []
            dispatch(updateProfileDataFromDB(educationData))

            // //saving experience data in redux
            let experienceData = []
            experienceData['key'] = keys.workExperience
            experienceData['data'] = data.experienceDetails ? data.experienceDetails : []
            dispatch(updateProfileDataFromDB(experienceData))

            // //saving language data in redux
            if (data?.currentUser?.candidate?.language) {
                let languageData = []
                languageData['key'] = keys.languages
                languageData['data'] = [{ language: data?.currentUser?.candidate?.language }]
                dispatch(updateProfileDataFromDB(languageData))
            }

            // //saving avatar data in redux
            if (data?.currentUser?.profileImage) {
                let avatarData = []
                avatarData['key'] = keys.selectAnAvatar
                avatarData['data'] = [{ profileImage: data?.currentUser?.profileImage }]
                dispatch(updateProfileDataFromDB(avatarData))
            }

            // //saving additional indo data in redux

            if (
                data?.currentUser?.candidate?.about ||
                (data?.currentUser?.candidate?.benefits &&
                    data?.currentUser?.candidate?.benefits.length > 0)
            ) {
                let avatarData = []
                avatarData['key'] = keys.additionalInfos
                avatarData['data'] = [
                    {
                        about: data?.currentUser?.candidate?.about,
                        benefits: data?.currentUser?.candidate?.benefits
                    }
                ]
                dispatch(updateProfileDataFromDB(avatarData))
            }
        },
        onError(error) {
            dispatch(loginFailure(error.message))
        }
    })

    useEffect(() => {
        setPrefCheck(false)
        if (startTimer === true) {
            setStartTimer(false)
            loaderTimerRef.current = setTimeout(() => {
                setShowChipSubmitButton(false)
                const tempMsg = [...msg]
                tempMsg.push({
                    question: '...',
                    options: [],
                    finalStep: false,
                    firstStep: false,
                    ans: '',
                    isLoader: true
                })
                setMsg(tempMsg)
            }, 1000)
            questionTimerRef.current = setTimeout(() => {
                setShowChipSubmitButton(true)
                const tempMsg = [...msg]
                if (activeQuestionIndex === 1) {
                    messages[
                        activeQuestionIndex
                    ].question = `Hi, ${msg[0].ans}, ${messages[activeQuestionIndex].question}`
                }
                tempMsg.push(messages[activeQuestionIndex])

                setMsg(tempMsg)
            }, 2000)
        }
    }, [startTimer])

    const {
        control,
        handleSubmit,
        formState: { errors, isDirty, isValid },
        register,
        setfocus,
        watch
    } = useForm({
        defaultValues: {
            email: '',
            password: '',
            terms: false
        }
    })
    let userPassword = watch('password')
    const AlwaysScrollToBottom = () => {
        const elementRef = useRef()
        useEffect(() => {
            elementRef.current.scrollIntoView({ behavior: 'smooth' })
        })
        return <div ref={elementRef} />
    }

    const handleChipClick = (optionIndex, index) => {
        const tempMsg = [...msg]
        setPerformScroll(false)
        if (index === tempMsg.length - 1) {
            tempMsg[tempMsg.length - 1].options[optionIndex].checked =
                !tempMsg[tempMsg.length - 1].options[optionIndex].checked
            if (tempMsg[tempMsg.length - 1].options.length >= 1) {
                tempMsg[tempMsg.length - 1].options.map(opt => {
                    if (opt.name == 'No Preference') {
                        opt.checked = false
                    }
                })
                setMsg(tempMsg)
            }
            const checkedData = tempMsg[tempMsg.length - 1].options.filter(op => op.checked)
            if (checkedData.length <= tempMsg[tempMsg.length - 1].allowedAnswers) {
                setMsg(tempMsg)
            } else {
                tempMsg[tempMsg.length - 1].options[optionIndex].checked =
                    !tempMsg[tempMsg.length - 1].options[optionIndex].checked
                setMsg(tempMsg)
            }
        }
        setPrefCheck(false)
    }

    const handleNoPref = item => {
        const tempMsg = [...msg]
        if (tempMsg[tempMsg.length - 1].options.length >= 1) {
            tempMsg[tempMsg.length - 1].options.map(opt => {
                if (opt.name != 'No Preference') {
                    opt.checked = false
                } else {
                    opt.checked = !prefCheck
                }
            })
            setMsg(tempMsg)
        }
        setItemkey(item.key)
        setPrefCheck(!prefCheck)
    }

    const onSubmit = data => {
        setApiError('')
        setPerformScroll(true)
        msg?.map(item => {
            data[item.key] = item.ans
        })
        registrationMutation({
            variables: {
                ...data
            }
        })
    }

    const handlePasswordValidation = value => {
        setValidationError('')
        if (
            value &&
            !value.match(PASSWORD_REGEX)
        ) {
            setApiError('')
            setValidationError('Invalid password format')
        }
    }

    const onLoginStart = useCallback(() => {
        setPageLoading(true)
    }, [])

    const handleGoogleLogin = ({ provider, data }) => {
        if (data) {
            let loginData = {}
            loginData['provider'] = provider
            loginData['accessToken'] = data.access_token
            loginData['email'] = data.email
            msg?.map(item => {
                loginData[item.key] = item.ans
            })
            setGoogleLoading(false)
            loginMutation({
                variables: {
                    ...loginData,
                    loginLocation: 'registration'
                }
            })
        }
    }

    const handleLinkedinLogin = ({ provider, data }) => {
        if (data) {
            let loginData = {}
            loginData['provider'] = provider
            loginData['accessToken'] = data.access_token
            msg?.map(item => {
                loginData[item.key] = item.ans
            })
            setLinkedinLoading(false)
            loginMutation({
                variables: {
                    ...loginData,
                    loginLocation: 'registration'
                }
            })
        }
    }

    const { linkedInLogin } = useLinkedIn({
        clientId: process.env.REACT_APP_LINKEDIN_LOGIN_CLIENT_ID,
        redirectUri: window.location.origin + process.env.REACT_APP_LINKEDIN_LOGIN_REDIRECT_URI,
        scope:'r_liteprofile,r_emailaddress',
        onSuccess: (code) => {
            let loginData = {}
            loginData['provider'] = 'linkedin'
            loginData['accessToken'] = code
            msg?.map(item => {
                loginData[item.key] = item.ans
            })
            loginMutation({
                variables: {
                    ...loginData,
                    loginLocation: 'registration',
                    referrerSite: window.location.origin
                }
            })
        },
        onError: (error) => {
          console.log(error);
        },
      });

    const handleChangeUserName = e => {
        const numberRegex = /^([^0-9=]*)$/
        const speacialCharacterRegex = /^[^!-\/:-@\[-`{-~]+$/
        if (
            e.target.value === '' ||
            (numberRegex.test(e.target.value) && speacialCharacterRegex.test(e.target.value))
        ) {
            localStorage.setItem('name', e.target.value)
            setName(e.target.value)
        }
    }

    const renderStepOne = () => {
        return (
            <StepOne
                setShowStepOne={setShowStepOne}
                onLoginStart={onLoginStart}
                handleLinkedinLogin={handleLinkedinLogin}
                setApiError={setApiError}
            />
        )
    }
    return (
        <Box>
            <PageMetaTags title='Registration | Bunton' />
            <Box sx={styles.container}>
                {showStepOne ? (
                    renderStepOne()
                ) : (
                    <Box>
                        <Box sx={styles.header}>
                            <Box sx={styles.formLogoBox}>
                                <RouterLink to='/'>
                                    <Box component='img' src={BuntonLogo} sx={styles.logo} />
                                </RouterLink>
                            </Box>
                            <Box
                                sx={styles.backIconContainer}
                                onClick={() => {
                                    let tempMsg = [...msg]
                                    if (
                                        tempMsg[tempMsg.length - 1].ans === '' &&
                                        tempMsg[tempMsg.length - 1].firstStep
                                    ) {
                                        setShowStepOne(true)
                                    }
                                    if (showRegistrationForm) {
                                        setShowRegistrationForm(false)
                                    }
                                    clearTimeout(loaderTimerRef.current)
                                    clearTimeout(questionTimerRef.current)

                                    if (tempMsg[tempMsg.length - 1].ans === '') {
                                        tempMsg = tempMsg.slice(0, -1)
                                        tempMsg[tempMsg.length - 1].ans = ''
                                    } else {
                                        tempMsg[tempMsg.length - 1].ans = ''
                                    }

                                    tempMsg[tempMsg.length - 1].options = tempMsg[
                                        tempMsg.length - 1
                                    ].options.map(msg => ({
                                        ...msg,
                                        checked: false
                                    }))
                                    if (tempMsg[tempMsg.length - 1].firstStep) {
                                        messages[tempMsg.length].question =
                                            masterMessages[tempMsg.length].question
                                    }
                                    messages[tempMsg.length - 1].options =
                                        masterMessages[tempMsg.length - 1].options

                                    if (tempMsg[tempMsg.length - 1].firstStep) {
                                        setShowTextInputAndButton(true)
                                        setShowChipSubmitButton(false)
                                    }
                                    setActiveQuestionIndex(activeQuestionIndex - 1)
                                    setMsg(tempMsg)
                                }}
                            >
                                
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Stack
                                sx={{
                                    width: {
                                        xs: '40%',
                                        sm: '20%',
                                        md: '10%'
                                    },
                                    marginBottom: '60px'
                                }}
                                spacing={400}
                            >
                                <Stepper
                                    activeStep={activeQuestionIndex}
                                    alternativeLabel
                                    connector={null}
                                >
                                    {messages.map((label, index) => (
                                        <Step
                                            key={label}
                                            sx={{ paddingLeft: '2px', paddingRight: '2px' }}
                                        >
                                            <StepLabel StepIconComponent={RegistrationStepper}>
                                                {}
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Stack>
                        </Box>
                        <Box sx={styles.formContainer}>
                            {showRegistrationForm ? (
                                <Box sx={styles.registrationStepContainer}>
                                    <ListItem sx={styles.msgWrapper}>
                                        <Box sx={styles.avatarContainer}>
                                            <Avatar alt='Bunton' src={AvatarDefault} />
                                        </Box>
                                        <Box sx={styles.msgContainer}>
                                            <Box sx={[styles.msgLeft, { maxWidth: '100%' }]}>
                                                {`Thank you ${msg[0].ans}! Lastly, please choose your option to sign up.`}
                                            </Box>
                                        </Box>
                                    </ListItem>
                                    <Box sx={styles.registrationFormContainer}>
                                        <Box sx={styles.socialLoginContainer}>
                                            <StyledSocialLoginButton
                                                label={
                                                    <Typography variant='paragraph' className='para-font-15px-proxima strong'>
                                                        {TextStrings.signUpWithLinkedin}
                                                    </Typography>
                                                }
                                                color='secondary'
                                                variant='contained'
                                                size='large'
                                                onClick={linkedInLogin}
                                                sx={{
                                                    width: '270px',
                                                    height: '56px'
                                                }}
                                                classes='social_login'
                                                disabled={linkedinLoading}
                                                startIcon={
                                                    <Icons
                                                        src={LinkedinLogo}
                                                        sx={{
                                                            height: '20px',
                                                            width: '19.56px',
                                                            marginTop: '-5px'
                                                        }}
                                                    />
                                                }
                                            />
                                            {/* <LoginSocialLinkedin
                                                isOnlyGetToken
                                                client_id={
                                                    process.env.REACT_APP_LINKEDIN_LOGIN_CLIENT_ID
                                                }
                                                client_secret={
                                                    process.env
                                                        .REACT_APP_LINKEDIN_LOGIN_CLIENT_SECRET
                                                }
                                                redirect_uri={REDIRECT_URI}
                                                scope={'r_liteprofile%20r_emailaddress'}
                                                onLoginStart={onLoginStart}
                                                onResolve={handleLinkedinLogin}
                                                onReject={err => {
                                                    setApiError(err)
                                                }}
                                            >
                                                <StyledSocialLoginButton
                                                    label={
                                                        <Typography variant='paragraph' className='para-font-15px-proxima strong'>
                                                            {TextStrings.signUpWithLinkedin}
                                                        </Typography>
                                                    }
                                                    color='secondary'
                                                    variant='contained'
                                                    size='large'
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        setLinkedinLoading(true)
                                                    }}
                                                    sx={{
                                                        width: '270px',
                                                        height: '56px'
                                                    }}
                                                    classes='social_login'
                                                    disabled={linkedinLoading}
                                                    startIcon={
                                                        <Icons
                                                            src={LinkedinLogo}
                                                            sx={{
                                                                height: '20px',
                                                                width: '19.56px',
                                                                marginTop: '-5px'
                                                            }}
                                                        />
                                                    }
                                                />
                                            </LoginSocialLinkedin> */}

                                            <LoginSocialGoogle
                                                client_id={
                                                    process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID
                                                }
                                                onLoginStart={onLoginStart}
                                                redirect_uri={REDIRECT_URI}
                                                scope='openid profile email'
                                                discoveryDocs='claims_supported'
                                                access_type='offline'
                                                onResolve={handleGoogleLogin}
                                                onReject={err => {
                                                    setApiError(err)
                                                }}
                                            >
                                                <StyledSocialLoginButton
                                                    label={
                                                        <Typography variant='paragraph' className='para-font-15px-proxima strong'>
                                                            {TextStrings.signUpWithGoogle}
                                                        </Typography>
                                                    }
                                                    color='secondary'
                                                    variant='contained'
                                                    size='large'
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        setGoogleLoading(true)
                                                    }}
                                                    classes='social_login'
                                                    disabled={googleLoading}
                                                    sx={{
                                                        width: '270px',
                                                        mt: '20px'
                                                    }}
                                                    startIcon={
                                                        <Icons
                                                            src={GoogleLogo}
                                                            sx={{
                                                                height: '20px',
                                                                width: '19.56px',
                                                                marginTop: '-5px'
                                                            }}
                                                        />
                                                    }
                                                    width='100%'
                                                />
                                            </LoginSocialGoogle>
                                            <Box sx={{
                                                        my: '20px'
                                                    }}>
                                                <Typography>OR</Typography>
                                            </Box>
                                            <Box>
                                            <Button
                                                label={
                                                    <Typography variant='paragraph' className='para-font-15px-proxima strong'>
                                                        {TextStrings.signUpWithEmail}
                                                    </Typography>
                                                }
                                                color='secondary'
                                                variant='contained'
                                                classes='social_login'
                                                size='large'
                                                onClick={() => {
                                                    setShowLoginByEmail(!showLoginByEmail)
                                                    setPerformScroll(true)
                                                }}
                                                // type='submit'
                                                sx={{width: '270px',backgroundColor: `${variables.WHITE}`,
                                                border: `3px solid ${variables.CYAN}`}}
                                            />
                                            </Box>
                                        </Box>
                                        {
                                            showLoginByEmail ? 
                                                <Box>
                                                    {
                                                        performScroll ? <AlwaysScrollToBottom/>:null
                                                    }
                                        <Box
                                            sx={{
                                                textAlign: 'center',
                                                m: '0 auto',
                                                width: {
                                                    xs: '80%',
                                                    lg: '60%'
                                                }
                                            }}
                                        >
                                            <Controller
                                                control={control}
                                                render={({ field: { onChange, value } }) => (
                                                    <FormControl sx={styles.emailContainer}>
                                                        <TextInput
                                                            label={TextStrings.email}
                                                            type='email'
                                                            name='email'
                                                            placeholder={Placeholder.enterEmail}
                                                            onChange={onChange}
                                                            formFieldError={
                                                                errors &&
                                                                errors.email &&
                                                                errors.email.message
                                                            }
                                                            required={true}
                                                            value={value}
                                                        />
                                                    </FormControl>
                                                )}
                                                name='email'
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: ErrorMessage.emptyEmail
                                                    },
                                                    pattern: {
                                                        value: Regex.emailRegex,
                                                        message: ErrorMessage.validEmail
                                                    }
                                                }}
                                            />
                                            <Controller
                                                control={control}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: ErrorMessage.emptyPassword
                                                    },
                                                    minLength: {
                                                        value: 6
                                                        // message: ErrorMessage.validPassword
                                                    },
                                                    pattern: {
                                                        value: PASSWORD_REGEX
                                                        // message: "Invalid password format"
                                                    }
                                                }}
                                                render={({ field: { onChange, value } }) => (
                                                    <FormControl
                                                        className='password_container'
                                                        sx={styles.passwordContainer}
                                                    >
                                                        <TextInput
                                                            label={TextStrings.password}
                                                            type={
                                                                showPassword ? 'text' : 'password'
                                                            }
                                                            name='password'
                                                            placeholder={Placeholder.enterPassword}
                                                            onChange={onChange}
                                                            formFieldError={
                                                                errors &&
                                                                errors.password &&
                                                                errors.password.message
                                                            }
                                                            required={true}
                                                            value={value}
                                                            // onPress={handlePasswordValidation(value)}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position='end'>
                                                                        <IconButton
                                                                            aria-label='toggle password visibility'
                                                                            onClick={
                                                                                handleClickShowPassword
                                                                            }
                                                                            onMouseDown={
                                                                                handleMouseDownPassword
                                                                            }
                                                                        >
                                                                            {showPassword ? (
                                                                                <VisibilityOff />
                                                                            ) : (
                                                                                <Visibility />
                                                                            )}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                            onBlur={handlePasswordValidation(value)}
                                                        />
                                                    </FormControl>
                                                )}
                                                name='password'
                                            />

                                            {apiError && apiError?.message ? (
                                                <FormHelperText
                                                    sx={{ marginLeft: '90px' }}
                                                    id='my-helper-text'
                                                    error={true}
                                                >
                                                    <strong>{apiError?.message}</strong>
                                                </FormHelperText>
                                            ) : apiError ? (
                                                <FormHelperText
                                                    error={true}
                                                    sx={{ marginLeft: '90px' }}
                                                    id='my-helper-text'
                                                >
                                                    <strong>{apiError}</strong>
                                                </FormHelperText>
                                            ) : validationError ? (
                                                <FormHelperText
                                                    error={true}
                                                    sx={{ marginLeft: '90px' }}
                                                    id='my-helper-text'
                                                >
                                                    <strong>{validationError}</strong>
                                                </FormHelperText>
                                            ) : null}
                                            {/* <Box sx={{mt: 3, mx: {
                                                            xs: '20px',
                                                            sm: '0px',
                                                            md: '0px'
                                                        }}}>
                                                <Typography
                                                    align='center'
                                                    variant='h5' className='small-size'
                                                    sx={{
                                                        maxWidth: '500px',
                                                        m: 'auto'
                                                    }}
                                                >
                                                    {PASSWORDMESSAGE}
                                                </Typography>
                                            </Box> */}

                                            <Box>
                                                <PasswordValidationSection value={userPassword}/>
                                            </Box>

                                            <Box
                                                sx={{
                                                    marginTop: '25px',
                                                    marginBottom: '25px'
                                                }}
                                            >
                                                <Controller
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: ErrorMessage.terms
                                                        }
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <>
                                                            <FormGroup
                                                                sx={{
                                                                    margin: '0 auto',
                                                                    width: 'fit-content',
                                                                    color: variables.BLUE
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            checked={value}
                                                                            onChange={onChange}
                                                                            icon={
                                                                                <StyledCheckedBoxUnchecked />
                                                                            }
                                                                            checkedIcon={
                                                                                <StyledCheckedBox>
                                                                                    <Box
                                                                                        component='img'
                                                                                        src={
                                                                                            CheckIcon
                                                                                        }
                                                                                        sx={{
                                                                                            height: '12px'
                                                                                        }}
                                                                                    />
                                                                                </StyledCheckedBox>
                                                                            }
                                                                        />
                                                                    }
                                                                    label={
                                                                        <>
                                                                            Accept 
                                                                            <RouterLink
                                                                                target='_blank'
                                                                                to={
                                                                                    '/terms-and-conditions'
                                                                                }
                                                                            >
                                                                                {' '}
                                                                                {TextStrings.terms}
                                                                            </RouterLink>{' '}
                                                                            &{' '}
                                                                            <RouterLink
                                                                                target='_blank'
                                                                                to={
                                                                                    '/privacy-policy'
                                                                                }
                                                                            >
                                                                                {TextStrings.policy}{' '}
                                                                            </RouterLink>{' '}
                                                                        </>
                                                                    }
                                                                />
                                                                {errors &&
                                                                errors.terms &&
                                                                errors.terms.message ? (
                                                                    <FormHelperText error={true} sx={{fontWeight:'bold'}}>
                                                                        {errors &&
                                                                            errors.terms &&
                                                                            errors.terms.message}
                                                                    </FormHelperText>
                                                                ) : null}
                                                            </FormGroup>
                                                        </>
                                                    )}
                                                    name='terms'
                                                />
                                            </Box>
                                            <Button
                                                label={TextStrings.submit}
                                                color='primary'
                                                variant='contained'
                                                size='large'
                                                disabled={!isDirty || !isValid}
                                                onClick={handleSubmit(onSubmit)}
                                                type='submit'
                                                sx={styles.socialLoginButton}
                                            />
                                        </Box>
                                                </Box>
                                            : null
                                        }
                                    </Box>
                                </Box>
                            ) : (
                                <>
                                    <Box sx={styles.chatContainer}>
                                        <Box
                                            sx={styles.chatScrollableContainer}
                                            className='hide-scrollbar'
                                        >
                                            {msg.map((item, index) => {
                                                return (
                                                    <>
                                                        {performScroll ? (
                                                            <AlwaysScrollToBottom />
                                                        ) : null}

                                                        <ListItem sx={styles.msgWrapper}>
                                                            <Box sx={styles.msgContainer}>
                                                                {item && item.isLoader ? (
                                                                    <Box sx={styles.msgLeftLoader}>
                                                                        <Box
                                                                            sx={{
                                                                                height: '50px'
                                                                            }}
                                                                            component='img'
                                                                            src={TypingLoader}
                                                                        />
                                                                    </Box>
                                                                ) : (
                                                                    <Box sx={styles.msgLeft}>
                                                                        {item.question}
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                            <Box sx={styles.avatarContainer}>
                                                                <Avatar
                                                                    alt='Bunton'
                                                                    src={AvatarDefault}
                                                                />
                                                            </Box>
                                                        </ListItem>
                                                        <Box sx={styles.chipWrapper}>
                                                            {item.options.map(
                                                                (opt, optionIndex) => {
                                                                    return item.ans ? null : (
                                                                        <RegistrationChipBox  
                                                                            disabled={
                                                                                item.ans
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            label={opt.label}
                                                                            checked={
                                                                                opt.name ==
                                                                                'No Preference'
                                                                                    ? prefCheck
                                                                                    : opt.checked
                                                                            }
                                                                            onClick={e => {
                                                                                if (
                                                                                    e.key !==
                                                                                    'Enter'
                                                                                ) {
                                                                                    opt.name ==
                                                                                    'No Preference'
                                                                                        ? handleNoPref(
                                                                                              item
                                                                                          )
                                                                                        : handleChipClick(
                                                                                              optionIndex,
                                                                                              index
                                                                                          )
                                                                                }
                                                                            }}
                                                                        />
                                                                    )
                                                                }
                                                            )}
                                                        </Box>

                                                        {item.ans && (
                                                            <ListItem
                                                                sx={[
                                                                    styles.msgWrapper,
                                                                    {
                                                                        justifyContent: 'flex-end'
                                                                    }
                                                                ]}
                                                            >
                                                                <Box sx={styles.msgRight}>
                                                                    {item.ansToShow}
                                                                </Box>
                                                            </ListItem>
                                                        )}
                                                    </>
                                                )
                                            })}
                                        </Box>
                                    </Box>
                                    <Box sx={styles.submitContainer}>
                                        {showTextInputAndButton && (
                                            <>
                                                <TextInput
                                                    type='text'
                                                    name='name'
                                                    placeholder={'Enter your first & last name'}
                                                    onChange={handleChangeUserName}
                                                    formFieldError={''}
                                                    required={true}
                                                    value={name}
                                                    sx={styles.textInput}
                                                />

                                                <Button
                                                    ref={firstNameFormRef}
                                                    label={TextStrings.submit}
                                                    color='primary'
                                                    variant='contained'
                                                    disabled={
                                                        name === '' ||
                                                        name.trim() === '' ||
                                                        name.trim().length < 2
                                                    }
                                                    size='large'
                                                    onClick={() => {
                                                        setShowTextInputAndButton(false)
                                                        const tempMsg = [...msg]
                                                        tempMsg[tempMsg.length - 1].ans = name
                                                        tempMsg[tempMsg.length - 1].ansToShow = name
                                                        setMsg(tempMsg)

                                                        setStartTimer(true)
                                                        setActiveQuestionIndex(
                                                            activeQuestionIndex + 1
                                                        )
                                                        setName('')
                                                    }}
                                                    sx={[styles.submitButton]}
                                                />
                                            </>
                                        )}
                                    </Box>

                                    {showChipSubmitButton && (
                                        <Box sx={styles.submitContainer}>
                                            <Button
                                                ref={chipFormRef}
                                                label={TextStrings.submit}
                                                color='primary'
                                                variant='contained'
                                                size='large'
                                                disabled={
                                                    msg[msg.length - 1].options.filter(
                                                        element => element.checked
                                                    ).length > 0 || prefCheck
                                                        ? false
                                                        : true
                                                }
                                                onClick={() => {
                                                    setPerformScroll(true)
                                                    const tempMsg = [...msg]
                                                    let data = tempMsg[
                                                        tempMsg.length - 1
                                                    ].options.filter(element => element.checked)
                                                    if (data.length > 0) {
                                                        tempMsg[tempMsg.length - 1].ansToShow =
                                                            Array.prototype.map
                                                                .call(data, function (item) {
                                                                    return item.label
                                                                })
                                                                .join(', ')
                                                        tempMsg[tempMsg.length - 1].ans =
                                                            tempMsg[tempMsg.length - 1].key ===
                                                            'experience'
                                                                ? Array.prototype.map
                                                                      .call(data, function (item) {
                                                                          return item.value
                                                                      })
                                                                      .join(', ')
                                                                : Array.prototype.map
                                                                      .call(data, function (item) {
                                                                          return item.label
                                                                      })
                                                                      .join(', ')
                                                        setMsg(tempMsg)
                                                        localStorage.setItem(tempMsg[
                                                            tempMsg.length - 1
                                                        ].key, tempMsg[
                                                            tempMsg.length - 1
                                                        ].ans)
                                                        setActiveQuestionIndex(
                                                            activeQuestionIndex + 1
                                                        )
                                                        if (tempMsg[tempMsg.length - 1].finalStep) {
                                                            setShowRegistrationForm(true)
                                                        } else {
                                                            setStartTimer(true)
                                                        }
                                                    }
                                                }}
                                                sx={styles.submitButton}
                                            />
                                        </Box>
                                    )}
                                </>
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
            {loading || loginLoading ? <Loader /> : null}
        </Box>
    )
}

export default Registration
